export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {},
  mutations: {
    LOGIN(state, payload) {
      state.user = payload
    },
    LOGOUT(state) {
      state.user = {}
    },
  },
  actions: {},
}

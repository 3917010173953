export default {
  namespaced: true,
  state: {
    cart: {
      items: [],
      subTotal: 0,
      total: 0,
      general_instructions: '',
    },
  },
  getters: {
    // getDryCleanItems: state => state.cart.items.filter(item => item.selected_service === 'dry_clean'),
    // getWashIronItems: state => state.cart.items.filter(item => item.selected_service === 'wash_iron'),
    // getIronItems: state => state.cart.items.filter(item => item.selected_service === 'iron'),
    // getDryItems: state => state.cart.items.filter(item => item.selected_service === 'dry'),
    getGeneralInstructions: state => state.cart.general_instructions,
    getSubTotal: state => state.cart.subTotal,
    getTotal: state => state.cart.total,
    getItems: state => state.cart.items,
    getCart: state => state.cart,
  },
  mutations: {
    ADD_TO_CART(state, payload) {
      // remove old item price from subtotal and total if exists
      // const editedItem = state.cart.items.filter(item => item.id === payload.id)[0]
      // if (editedItem) {
      //   state.cart.subTotal -= editedItem.subTotal
      //   state.cart.total -= editedItem.total
      // }

      // delete old item if exists
      // state.cart.items = state.cart.items.filter(item => !_.isEqual(_.omit(item, ['quantity', 'total_price', 'item_price', 'notes']), _.omit(payload, ['quantity', 'total_price', 'item_price', 'notes'])))
      state.cart.items = state.cart.items.filter(item => item.cart_id !== payload.cart_id)
      // add new item
      state.cart.items.push(payload)

      // add new item price to subtotal and total
      state.cart.subTotal = 0
      state.cart.total = 0
      state.cart.items.forEach(item => {
        state.cart.subTotal += item.total_price
        state.cart.total += item.total_price
      })
      // state.cart.subTotal += payload.item_price
      // state.cart.total += payload.total_price
    },
    REMOVE_FROM_CART(state, payload) {
      state.cart.subTotal -= payload.total_price
      state.cart.total -= payload.total_price

      // state.cart.items = state.cart.items.filter(item => !_.isEqual(item, payload))
      state.cart.items = state.cart.items.filter(item => item.cart_id !== payload.cart_id)
    },
    RESET_CART(state) {
      state.cart = {
        items: [],
        subTotal: 0,
        total: 0,
        general_instructions: '',
      }
    },
    INCREACE_QUANTITY(state, payload) {
      state.cart.items.filter(item => item.cart_id === payload.cart_id)[0].quantity += 1
    },
    DECREASE_QUANTITY(state, payload) {
      state.cart.items.filter(item => item.cart_id === payload.cart_id)[0].quantity -= 1
    },
    ADD_PROMO_CODE(state, payload) {
      state.cart.promoCode = payload
    },
    ADD_GENERAL_INSTRUCTIONS(state, payload) {
      state.cart.general_instructions = payload
    },
    REMOVE_GENERAL_INSTRUCTIONS(state) {
      state.cart.general_instructions = ''
    },
    ADD_REMAINING_DATA(state, payload) {
      state.cart.customer = payload.customer
      state.cart.pickupAddress = payload.pickupAddress
      state.cart.deliveryAddress = payload.deliveryAddress
      state.cart.promoCode = payload.promoCode
      state.cart.hasFast = payload.hasFast
      state.cart.hasPickup = payload.hasPickup
      state.cart.hasDelivery = payload.hasDelivery
      state.cart.pickupDay = payload.pickupDay
      state.cart.deliveryDay = payload.deliveryDay
      state.cart.pickupTime = payload.pickupTime
      state.cart.deliveryTime = payload.deliveryTime
      state.cart.general_instructions = payload.generalInstructions
    },
  },
  actions: {
  },
}
